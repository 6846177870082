.swiper {
  overflow: visible !important;
  width: 100%;
  height: 100%;
  user-select: none;

  --swiper-theme-color: rgb(17, 24, 39);
  --swiper-navigation-size: 22px;
  --swiper-navigation-bg-color: rgb(243, 244, 246, 0.8);
  --swiper-scrollbar-size: 10px;
  --swiper-scrollbar-sides-offset: 16px;
  --swiper-scrollbar-right: 16px;
  --swiper-scrollbar-bottom: 8px;

  :global {
    .swiper-button-next,
    .swiper-button-prev {
      width: 48px;
      height: 48px;
      background-color: var(--swiper-navigation-bg-color);
      border-radius: 100%;
      transition: background-color 0.2s;

      &:not(.swiper-button-disabled) {
        &:hover {
          background-color: rgba(229, 231, 235, 0.8);
        }
      }
    }
  }
}

.itemSwiperAutoHeight {
  :global {
    .swiper-slide {
      height: auto !important;
    }
  }
}

.itemSwiper {
  // --swiper-navigation-size: 30px
  :global {
    .swiper-pagination-fraction {
      background-color: var(--swiper-navigation-bg-color);
      color: var(--swiper-theme-color);
      width: max-content;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 4px 8px;
      border-radius: 50px;
      font-size: small;
    }
    .swiper-button-next,
    .swiper-button-prev {
      --swiper-navigation-size: 15px;

      border-radius: 50px;
      top: 45%;
      width: 35px;
      height: 35px;
    }
  }
}
