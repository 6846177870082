@import "../../../styles/variables";

.richtext {
  :target {
    scroll-margin-top: $navigation_height + 32px;
  }
}
.fullWidthContainer {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
