/* Cookiebot Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Cookiebot Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}
